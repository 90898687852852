import React from "react";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import lawledger1 from "../../assets/proj/ldekon.png";
import lawledgerimg1 from "../../assets/projects/ldekonomi.jpg";
import { ldekonomi } from "../constants/textCostants";
import { useLanguage } from "../constants/LanguageContext";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// Utility function to format text with bold for **wrapped words**
const formatBoldText = (text) => {
  const parts = text.split(/(\*\*.*?\*\*)/); // Split on **bold** markers
  return parts.map((part, index) =>
    part.startsWith("**") && part.endsWith("**") ? (
      <strong key={index}>{part.slice(2, -2)}</strong> // Remove ** and wrap in <strong>
    ) : (
      part
    )
  );
};

const ProjectLDekonomi = () => {
  const { language } = useLanguage();
  const content = ldekonomi[language] || ldekonomi["en"];

  return (
    <Box>
      {/* Hero Section with Background Image */}
      <Box
        sx={{
          width: "100%",
          height: "400px",
          backgroundImage: `url(${lawledgerimg1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "white",
          fontFamily: "Montserrat, sans-serif",
          padding: "20px",
        }}
      >
        {/* Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay
            zIndex: 1,
          }}
        />

        <Box
          sx={{
            position: "relative",
            zIndex: 2,
          }}
        >
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", fontSize: "36px", mb: 2 }}
          >
            {content.title}
          </Typography>
        </Box>
      </Box>

      {/* Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          padding: "100px 0 20px 0 ",
          cursor: "pointer",
        }}
        onClick={() => window.open("https://ldekonomikonsult.se/", "_blank")}
      >
        <ArrowDownwardIcon
          sx={{
            transform: "rotate(-45deg)",
            fontSize: "30px",
            marginRight: "8px",
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "black",
            textTransform: "uppercase",
            "&:hover": {
              borderBottom: "2px solid black",
            },
          }}
        >
          LD Ekonomi & Konsult
        </Typography>
      </Box>

      {/* Project Details Section */}
      <Box
        sx={{
          maxWidth: "80%",
          margin: "0 auto",
          padding: "50px 20px",
        }}
      >
        {/* Main Image */}
        <Card sx={{ boxShadow: 3, marginBottom: 4 }}>
          <CardMedia
            component="img"
            image={lawledger1}
            alt="Allekonomi Project Image"
            sx={{ borderRadius: "8px" }}
          />
        </Card>

        {/* Project Sections in Improved Layout */}
        {[
          { title: content.overviewTitle, text: content.overviewContent },
          {
            title: content.technologiesTitle,
            text: content.technologiesContent,
          },
          {
            title: content.functionalityTitle,
            text: content.functionalityContent,
          },
          { title: content.resultsTitle, text: content.resultsContent },
        ].map((section, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              alignItems: "flex-start",

              justifyContent: "space-between",
              mb: 4,
              borderBottom: "1px solid #dfdada",
              paddingBlock: "50px",
              marginBlock: "50px",
            }}
          >
            {/* Title Column */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                minWidth: "220px",
                textAlign: { xs: "center", md: "left" },
                marginBottom: { xs: "10px", md: 0 },
              }}
            >
              {section.title}
            </Typography>
            {/* Content Column */}
            <Typography
              sx={{
                textAlign: "justify",
                lineHeight: 1.7,
                maxWidth: "800px",
              }}
            >
              {formatBoldText(section.text)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectLDekonomi;
