import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import NavbarFixed from "./components/navbar/NavbarFixed";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Blog from "./pages/blog/Blog";
import Simple from "./components/blogComponents/Simple";
import Dynamic from "./components/blogComponents/Dynamic";
import Ecommerce from "./components/blogComponents/Ecommerce";
import { LanguageProvider } from "./components/constants/LanguageContext";
import Cursor from "./components/Cursor";
import Seo from "./components/blogComponents/Seo";
import CustomerCase from "./pages/CustomerCase";
import ProjectAllekonomi from "./components/customerCaseProjects/ProjectAllekonomi";
import ProjectAgloRedovisning from "./components/customerCaseProjects/ProjectAgloRedovisning";
import ProjectBeautySpace from "./components/customerCaseProjects/ProjectBeautySpace";
import ProjectShoutItOut from "./components/customerCaseProjects/ProjectShoutItOut";
import ProjectSvRent from "./components/customerCaseProjects/ProjectSvRent";
import Cookies from "./pages/home/Cookies";
import FAQ from "./components/FAQ";
import ContactPage from "./pages/ContactPage";
import Footer from "./components/footer/Footer";
import PrivacyPolicy from "./pages/home/PrivacyPolicy";
import ProjectLawLedjer from "./components/customerCaseProjects/ProjectLawLedjer";
import ProjectLDekonomi from "./components/customerCaseProjects/ProjectLDekonomi";

// Create a custom theme with Montserrat font
const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Cursor />
      <LanguageProvider>
        <NavbarFixed />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/simple" element={<Simple />}></Route>
          <Route path="/blog/dynamic" element={<Dynamic />}></Route>
          <Route path="/blog/ecommerce" element={<Ecommerce />}></Route>
          <Route path="/blog/seo" element={<Seo />}></Route>
          <Route path="/customer-case" element={<CustomerCase />}></Route>
          <Route
            path="/customer-case/allekonomi"
            element={<ProjectAllekonomi />}
          ></Route>
          <Route
            path="/customer-case/agloredovisning"
            element={<ProjectAgloRedovisning />}
          ></Route>
          <Route
            path="/customer-case/beauty-space"
            element={<ProjectBeautySpace />}
          ></Route>
          <Route
            path="/customer-case/shoutitout"
            element={<ProjectShoutItOut />}
          ></Route>
          <Route
            path="/customer-case/lawledger"
            element={<ProjectLawLedjer />}
          ></Route>
          <Route
            path="/customer-case/ldekonomi"
            element={<ProjectLDekonomi />}
          ></Route>
          <Route
            path="/customer-case/svrent"
            element={<ProjectSvRent />}
          ></Route>
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>

        <Footer />
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
