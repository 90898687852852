import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { FaLinkedin, FaGithub, FaTiktok, FaFacebook } from "react-icons/fa";
import cityImage from "../../assets/city.webp"; // Update the path based on your project structure

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, rgba(1, 1, 1, 1), rgba(0, 0, 0, 0.9)), url(${cityImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "white",
        padding: "50px 10px",
        textAlign: "center",
        fontFamily: '"Montserrat", serif',
      }}
    >
      {/* Navigation Links */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", md: "center" },
          alignItems: "center",
          gap: { xs: 1, md: 4 },
          fontSize: "14px",
          textTransform: "uppercase",
          paddingBottom: "10px",
        }}
      >
        {[
          { label: "Home", href: "/" },
          { label: "About Me", href: "/about" },
          { label: "Portfolio", href: "/customer-case" },
          { label: "Blog", href: "/blog" },
          { label: "FAQ", href: "/faq" },
          { label: "Contact", href: "/contact" },
        ].map((link, index) => (
          <Link
            key={index}
            href={link.href}
            color="inherit"
            underline="none"
            aria-label="Alina Samoteev || Fullstack Developer , portfolio website "
            sx={{
              flexBasis: { xs: "45%", md: "auto" }, // Makes two links per row on XS
              textAlign: "center",
              marginBottom: { xs: "10px", md: "0" },
            }}
          >
            {link.label}
          </Link>
        ))}
      </Box>

      {/* Contact Info */}
      <Typography
        sx={{
          fontSize: "12px",
          margin: "10px 0",
          textTransform: "capitalize",
        }}
      >
        Alina Samoteev, Stockholm 2024
      </Typography>

      {/* Social Links */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          margin: "10px 0",
          fontSize: "20px",
        }}
      >
        <Link
          href="https://www.linkedin.com/in/alina-samoteev-627836a5/"
          target="_blank"
          color="inherit"
          underline="none"
          aria-label="Alina Samoteev ||Developer, linkedin "
        >
          <FaLinkedin />
        </Link>
        <Link
          href="https://github.com/AlinaCGM"
          target="_blank"
          color="inherit"
          underline="none"
          aria-label="Alina Samoteev ||Developer, github "
        >
          <FaGithub />
        </Link>
        <Link
          href="https://www.tiktok.com/@samoteev.dev?_t=8rmOcwLejXK&_r=1"
          target="_blank"
          color="inherit"
          underline="none"
          aria-label="Alina Samoteev ||Developer , tiktok"
        >
          <FaTiktok />
        </Link>
        <Link
          href="https://www.facebook.com/shoutitout.se/"
          target="_blank"
          color="inherit"
          underline="none"
          aria-label="Alina Samoteev ||Developer, shoutitout.se link to review platform  "
        >
          <FaFacebook />
        </Link>
      </Box>

      {/* Footer Bottom Text */}
      <Box
        sx={{
          marginTop: "10px",
          paddingTop: "10px",
          fontSize: "12px",
          color: "#3f3d3d",
        }}
      >
        <Link
          href="/cookies"
          color="inherit"
          underline="none"
          sx={{ marginRight: "10px" }}
          aria-label="Alina Samoteev ||Developer, cookies information "
        >
          Cookies
        </Link>
        |
        <Link
          href="/privacy"
          color="inherit"
          underline="none"
          sx={{ marginLeft: "10px" }}
          aria-label="Alina Samoteev ||Developer , privacy policy"
        >
          Privacy Policy
        </Link>
      </Box>
      <Typography sx={{ fontSize: "12px", marginTop: "5px" }}>
        © 2024 AS
      </Typography>
    </Box>
  );
};

export default Footer;
