import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import HeroBlog from "../../components/blogComponents/HeroBlog";
import { useLanguage } from "../../components/constants/LanguageContext";
import { blogText } from "../../components/constants/textCostants";

import simpleImage from "../../assets/proj/blogSimple.jpg";
import dynamicImage from "../../assets/proj/blogDynamoc.jpg";
import ecommerceImage from "../../assets/proj/blogEcommerce.jpg";
import seoImage from "../../assets/proj/google.jpg";
import { useNavigate } from "react-router-dom";
import BlogAbout from "../../components/blogComponents/BlogAbout";

const Blog = () => {
  const { language } = useLanguage();
  const text = blogText[language] || blogText.en; // Default to English if language is undefined
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/blog");
  };

  const images = [simpleImage, dynamicImage, ecommerceImage, seoImage];

  return (
    <>
      <HeroBlog />

      <Box
        sx={{
          maxWidth: { xs: "100%", md: "80%" },
          margin: "auto",
          padding: "100px 20px",
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        <BlogAbout />
        {text.topics.map((topic, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: index % 2 === 0 ? "row" : "row-reverse",
              },
              alignItems: "center",
              mb: 4,
              p: 2,
              backgroundColor: index % 2 === 0 ? "#F9F9F9" : "#EFEFEF",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardMedia
              component="img"
              image={images[index]}
              alt={`${topic.title} Image`}
              sx={{
                width: { xs: "100%", md: "40%" },
                height: "auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
            <CardContent
              sx={{ width: { xs: "100%", md: "60%" }, textAlign: "left" }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                {topic.title}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {topic.description}
              </Typography>
              <Button
                component={Link}
                onClick={handleReadMoreClick}
                to={topic.path}
                variant="contained"
                color="primary"
                sx={{
                  marginTop: 2,
                  backgroundColor: "#4e4d4d",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#7e7e7e",
                    color: "black",
                  },
                }}
              >
                {text.readMore}
              </Button>
            </CardContent>
          </Card>
        ))}
        <Divider sx={{ marginY: 4 }} />
      </Box>
    </>
  );
};

export default Blog;
