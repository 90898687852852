import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const links = [
  {
    label: "WordPress Website in 10 Minutes",
    url: "https://www.youtube.com/watch?v=AABmCvjd_iU",
  },
  {
    label: "Free Website in 2024",
    url: "https://www.youtube.com/watch?v=kdOCHkgeLNc",
  },
  {
    label: "Difference Between Custom-Built and Template-Based Websites",
    url: "https://www.youtube.com/watch?v=Ohy5IHC_mzs",
  },
  {
    label: "React Tutorial",
    url: "https://www.youtube.com/watch?v=SqcY0GlETPk",
  },
  {
    label: "SEO Importance",
    url: "https://www.youtube.com/watch?v=MYE6T_gd7H0",
  },
];

function UsefulLinks() {
  return (
    <Box sx={{ p: 2, bgcolor: "#f0f4f8", borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Useful Links
      </Typography>
      <List>
        {links.map((link, index) => (
          <ListItem key={index} sx={{ p: 1 }}>
            <ListItemIcon>
              <ArrowForwardIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  color="text.primary"
                  sx={{ fontWeight: "medium" }}
                  aria-label="Alina Samoteev ||Developer ,useful links for users"
                >
                  {link.label}
                </Link>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default UsefulLinks;
