import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import signImage from "../../assets/sign.jpg";
import { welcomeText } from "../constants/textCostants"; // Import translations
import { useLanguage } from "../constants/LanguageContext"; // Import language context
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Fade, Slide } from "react-awesome-reveal";

const Welcome = () => {
  const { language } = useLanguage();
  const welcome = welcomeText[language] || welcomeText["en"]; // Fallback to English if language is missing

  const navigate = useNavigate();
  const handleReadMoreClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/blog");
  };

  return (
    <Box
      sx={{
        maxWidth: "1200px", // Set max container width
        padding: "50px 20px",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex", // Enable flexbox
          flexDirection: { xs: "column", lg: "row" }, // Column for smaller screens, row for large screens
          alignItems: "center",
          gap: 3, // Add space between children
        }}
      >
        {/* Text Content Section */}
        <Box
          sx={{
            flexBasis: { xs: "100%", lg: "70%" }, // Explicitly set flex-basis for widths
            flexGrow: 0, // Prevent shrinking or growing
            flexShrink: 0,
            backgroundColor: "#f9f9f9",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Slide direction="left" delay={500}>
            <Fade delay={500}>
              {/* Title */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "20px 0",
                }}
              >
                <ArrowDownwardIcon
                  sx={{
                    transform: "rotate(-45deg)",
                    fontSize: "30px",
                    marginRight: "8px",
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  {welcome.title}
                </Typography>
              </Box>

              {welcome.content.map((paragraph, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Montserrat, sans-serif",
                    lineHeight: "1.6",
                    marginBottom: "20px",
                  }}
                >
                  {paragraph}
                </Typography>
              ))}
              <Link
                to="/blog"
                style={{ textDecoration: "none" }}
                aria-label="Läs mer om vår blogg"
              >
                <Button
                  variant="contained"
                  onClick={handleReadMoreClick}
                  color="primary"
                  sx={{
                    marginTop: 2,
                    backgroundColor: "#4e4d4d",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#7e7e7e",
                      color: "black",
                    },
                  }}
                >
                  {welcome.button}
                </Button>
              </Link>
            </Fade>
          </Slide>
        </Box>

        {/* Image Section */}
        <Box
          sx={{
            flexBasis: { xs: "100%", lg: "30%" }, // Explicitly set flex-basis for widths
            flexGrow: 0, // Prevent shrinking or growing
            flexShrink: 0,
            textAlign: "center",
          }}
        >
          <Slide direction="right" delay={500}>
            <Fade delay={500}>
              <Box
                component="img"
                src={signImage}
                alt="Professionell hemsida utvecklad på kort tid för företag i Stockholm"
                sx={{
                  maxWidth: "100%",
                  height: "auto",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              />
            </Fade>
          </Slide>
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
