import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { blogAbout } from "../../components/constants/textCostants";
import { useLanguage } from "../../components/constants/LanguageContext";

const BlogAbout = () => {
  const { language } = useLanguage();
  const text = blogAbout[language] || blogAbout.en;

  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate("/contact");
  };

  return (
    <Box
      sx={{
        padding: "20px",
        margin: "auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "1200px",
        fontFamily: "Montserrat, sans-serif",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        {text.title}
      </Typography>

      {text.content.map((paragraph, index) => (
        <Typography key={index} variant="body1" sx={{ mb: 2 }}>
          {paragraph}
        </Typography>
      ))}

      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleReadMoreClick}
          sx={{
            backgroundColor: "#4e4d4d",
            color: "white",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: "#7e7e7e",
              color: "black",
            },
          }}
        >
          {text.button}
        </Button>
      </Box>
    </Box>
  );
};

export default BlogAbout;
